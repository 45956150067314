const isSubmoduleReducer = (state = [], action) => {
  switch (action.type) {
    case 'SET_IS_SUBMODULE':
      return action.isSubmodule;
    default:
      return state;
  }
};

export default isSubmoduleReducer;
